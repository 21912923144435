import React, { useState, useEffect, useRef, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import s from '../styles/Edit_profile.module.css'; // Подключение стилей
import { AuthContext } from '../AuthContext';
import { useTranslation } from 'react-i18next';

function EditProfile() {
  const [profile, setProfile] = useState({
    name: '',
    city: '',
    age: '',
    gender: '',
    bio: ''
  });
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const bioRef = useRef(null); // Используем useRef для bio
  const { setCurrentUserProfile } = useContext(AuthContext);
  const { t } = useTranslation();


  // Получение данных профиля при загрузке страницы
  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await fetch('/api/edit_profile', {
          method: 'GET',
          credentials: 'include',  // Включаем куки для сессии
        });

        if (response.status === 401) {
          navigate('/login');  // Перенаправляем на логин, если не авторизован
        } else if (response.status === 200) {
          const data = await response.json();
          setProfile(data.profile);
        } else {
          setError('Profile not found');
        }
      } catch (error) {
        setError('Error fetching profile data');
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, [navigate]);

  useEffect(() => {
    // Устанавливаем высоту bio после загрузки профиля
    if (bioRef.current) {
      bioRef.current.style.height = 'auto'; // Сначала сбрасываем высоту
      bioRef.current.style.height = `${bioRef.current.scrollHeight}px`; // Устанавливаем высоту по содержимому
    }
  }, [profile.bio]);

  // Обработка формы отправки профиля
  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('name', profile.name);
    formData.append('city', profile.city);
    formData.append('age', profile.age);
    formData.append('gender', profile.gender);
    formData.append('bio', profile.bio);
    

    try {
      const response = await fetch('/api/edit_profile', {
        method: 'POST',
        credentials: 'include',  // Включаем куки для сессии
        body: formData,
      });

      if (response.ok) {
        setSuccess(true);
        navigate('/menu');  // Перенаправляем на страницу профиля после успешного редактирования
      } else {
        const data = await response.json();
        setError(data.error || 'Failed to update profile');
      }
    } catch (error) {
      setError('Error submitting profile data');
    }
  };

  const handleEditPhoto = (photoId) => {
    navigate(`/edit_photo`);
  };
  

  // Функция для удаления профиля
  const handleDeleteProfile = async () => {
    try {
      const response = await fetch('/api/delete_profile', {
        method: 'POST',
        credentials: 'include',  // Включаем куки для сессии
      });

      if (response.ok) {
        setCurrentUserProfile(null);
        // alert('Profile deleted successfully');
        navigate('/');  // Перенаправляем пользователя на главную страницу после удаления
      } else {
        const data = await response.json();
        setError(data.error || 'Failed to delete profile');
      }
    } catch (error) {
      setError('Error deleting profile');
    }
  };

  if (loading) {
    return <div className='loading'>{t("Loading")}...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div>   
      <h1>Edit Profile</h1>
      {success && <p>Profile updated successfully!</p>}

      <div className={s.edit_container}>
        <div className={s.edit_form}>
          <form onSubmit={handleSubmit}>
            <div className={s.form_group}>
              <label htmlFor="name">Name:</label>
              <input
                id="name"
                type="text"
                value={profile.name}
                onChange={(e) => setProfile({ ...profile, name: e.target.value })}
                required
              />
            </div>
            <div className={s.form_group}>
              <label htmlFor="city">City:</label>
              <input
                id="city"
                type="text"
                value={profile.city}
                onChange={(e) => setProfile({ ...profile, city: e.target.value })}
                required
              />
            </div>
            <div className={s.form_group}>
              <label htmlFor="age">Age:</label>
              <input
                id="age"
                type="number"
                value={profile.age}
                onChange={(e) => setProfile({ ...profile, age: e.target.value })}
                required
              />
            </div>
            <div className={s.form_group}>
              <label htmlFor="gender">Gender:</label>
              <select
                id="gender"
                value={profile.gender}
                onChange={(e) => setProfile({ ...profile, gender: e.target.value })}
                required
              >
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Other">Other</option>
              </select>
            </div>
            <div className={s.form_group}>
              <label htmlFor="bio">Bio:</label>
              <textarea
                id="bio"
                ref={bioRef} // Привязка ref к bio
                value={profile.bio}
                onChange={(e) => setProfile({ ...profile, bio: e.target.value })}
                rows={3}
                onInput={(e) => {
                  e.target.style.height = 'auto'; // Сначала сбрасываем высоту
                  e.target.style.height = `${e.target.scrollHeight}px`; // Устанавливаем высоту по содержимому
                }}
              />
            </div>
          </form>
        </div>
      </div>
      <div className={s.btn_edit_container}>
        <div className={s.btn_edit}>
        <button onClick={() => handleEditPhoto(123)}>Edit Photo</button>
        </div>
      </div>

      <div className={s.btn_edit_container}>
        <div className={s.btn_edit}>
          <button type="submit" onClick={handleSubmit}>Update Profile</button>
          <button onClick={handleDeleteProfile}>Delete Profile</button> 
        </div>
      </div>
    </div>
  );
}

export default EditProfile;
