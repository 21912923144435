import React, { useEffect, useState, useRef, useCallback, memo } from 'react';
import { useParams } from 'react-router-dom';
import s from '../styles/ChatWindow.module.css';
import { useTranslation } from 'react-i18next';

// Компонент для сообщения, мемоизируем его
const Message = memo(({ message, currentProfileId }) => (
  <div
    key={message.message_id}
    className={`${s.message} ${message.sender_profile_id === currentProfileId ? s.sent : s.received}`}
  >
    <p>{message.content}</p>
    <span className={s.timestamp}>
      {new Date(message.timestamp).toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      })}
    </span>
  </div>
));

function ChatWindow() {
  const { conversationId } = useParams();
  const [messages, setMessages] = useState([]);
  const [participant, setParticipant] = useState(null);
  const [newMessage, setNewMessage] = useState('');
  const [currentProfileId, setCurrentProfileId] = useState(null);
  const [isLoadingMore, setIsLoadingMore] = useState(false); 
  const [hasMoreMessages, setHasMoreMessages] = useState(true); 
  const messagesRef = useRef(null);
  const messagesEndRef = useRef(null);
  const [isFirstLoad, setIsFirstLoad] = useState(true); // Флаг для первой загрузки
  const isRequestInProgressRef = useRef(false); // Используем useRef для отслеживания запросов
  const [messageSent, setMessageSent] = useState(false);
  const { t } = useTranslation();

  // Функция для загрузки сообщений
  const fetchMessages = useCallback(async (options = {}) => {
    if (isRequestInProgressRef.current) return; // Проверяем, идет ли запрос
  isRequestInProgressRef.current = true; // Устанавливаем флаг, что запрос выполняется

    const { beforeMessageId } = options;
    const limit = 20;

    let url = `/api/conversations/${conversationId}/messages?limit=${limit}`;
    if (beforeMessageId) {
      url += `&before_message_id=${beforeMessageId}`;
    }

    try {
      const response = await fetch(url, {
        credentials: 'include',
      });
      if (response.ok) {
        const data = await response.json();
        if (data.messages.length === 0) {
          setHasMoreMessages(false);
        } else {
          setMessages((prevMessages) => [...data.messages, ...prevMessages]);
        }
        // Сохраняем данные второго участника чата в отдельное состояние
      if (data.participant) {
        setParticipant(data.participant);  // Установим состояние с данными второго участника
      }
      } else {
        console.error('Failed to fetch messages');
      }
    } catch (error) {
      console.error('Error fetching messages:', error);
    } finally {
      isRequestInProgressRef.current = false; // Сбрасываем флаг после завершения запроса
      setIsLoadingMore(false);
    }
  }, [conversationId]);

  useEffect(() => {
    fetchMessages();
  }, [conversationId, fetchMessages]);

  useEffect(() => {
    const fetchCurrentProfile = async () => {
      try {
        const response = await fetch('/api/current_user', {
          credentials: 'include',
        });
        if (response.ok) {
          const data = await response.json();
          setCurrentProfileId(data.user_id);
        } else {
          console.error('Failed to fetch current profile');
        }
      } catch (error) {
        console.error('Error fetching current profile:', error);
      }
    };

    fetchCurrentProfile();
  }, []);

  const debounce = (func, delay) => {
    let timer;
    return function (...args) {
      clearTimeout(timer);
      timer = setTimeout(() => func.apply(this, args), delay);
    };
  };

 

  const handleScroll = debounce(() => {
    const container = messagesRef.current;
  
    // Если уже идет подгрузка сообщений или нет больше сообщений для подгрузки, то выходим
    if (isLoadingMore || !hasMoreMessages) return;
  
    const scrollTop = container.scrollTop;
    const scrollHeight = container.scrollHeight;
    const clientHeight = container.clientHeight;
  
    // Считаем процент прокрутки
    const scrollPercentage = (scrollTop / (scrollHeight - clientHeight)) * 100;
  
    // Если мы находимся выше 20% от верхней границы
    if (scrollPercentage < 50 && hasMoreMessages && !isLoadingMore) {
      const oldestMessageId = messages[0]?.message_id;
  
      // Сохраняем текущее положение скролла относительно нижней части
      const scrollOffset = scrollHeight - scrollTop;
  
      setIsLoadingMore(true);
  
      fetchMessages({ beforeMessageId: oldestMessageId }).then(() => {
        // Подгрузка завершена, обновляем скролл
        setTimeout(() => {
          const newScrollHeight = container.scrollHeight;
          container.scrollTop = newScrollHeight - scrollOffset; // Восстанавливаем положение
          setIsLoadingMore(false); // Разрешаем подгрузку следующей партии сообщений
        }, 0); // Обновляем скролл после рендера новых сообщений
      });
    }
  }, 100);


  useEffect(() => {
    if (isFirstLoad && messages.length > 0) {
      // Прокрутка вниз после первой загрузки
      if (messagesEndRef.current) {
        messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
      }
      
      // Задержка сброса флага после прокрутки
      setTimeout(() => {
        setIsFirstLoad(false); // Сбрасываем флаг после прокрутки вниз
      }, 500); // Увеличьте задержку, если требуется больше времени для прокрутки
    }
  }, [messages, isFirstLoad]);


  const handleGoToNewMessage = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
};

useEffect(() => {
  if (messageSent) {
  handleGoToNewMessage(); // Вызываем функцию после добавления нового сообщения
  setMessageSent(false); // Сбрасываем состояние после прокрутки
}
}, [messageSent]); //
  
  // Функция для отправки сообщения
  const sendMessage = async () => {
    if (newMessage.trim() === '') return;

    try {
      const response = await fetch(`/api/conversations/${conversationId}/messages`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({ content: newMessage }),
      });

      if (response.ok) {
        const message = await response.json();
        setMessages((prevMessages) => [...prevMessages, message]);
        setNewMessage('');
        setMessageSent(true); // Устанавливаем флаг, что сообщение отправлено
      } else {
        console.error('Failed to send message');
      }
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  return (
    <div>
      {participant && ( 
     <div>
     {/* <img src={`/api/uploads/${participant.photo}`} alt={`${participant.name}'s icon`} className={s.user_icon} /> */}
       <div
       className={s.chat_item}
    // onClick={() => startChat(chat.conversation_id)} // Обработчик нажатия на всю строку
    style={{ cursor: 'pointer' }} // Устанавливаем стиль курсора, чтобы было видно, что элемент кликабельный
     >
    <img src={`/api/uploads/${participant.photo}`} alt={`${participant.name}'s icon`} className={s.user_icon} />
      
    <div className={s.chat_content}>
       <div className={s.chat_header}>
          <div className={s.chat_name}>{participant.name}</div>          
       </div>
       </div>
     </div>
     </div>
     )}
    <div className={s.chat_window}>
    
      <div
        className={s.messages}
        ref={messagesRef}
        onScroll={handleScroll}
      >
        {messages.map((message) => (
          <Message key={message.message_id} message={message} currentProfileId={currentProfileId} />
        ))}
        <div className={s.messagesEndRef} ref={messagesEndRef} />
      </div>

      <div className={s.message_input}>
        <input
          type="text"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          placeholder={t("input_sms")}
        />
        <button onClick={sendMessage}>{t("send")}</button>
      </div>
    </div>
    </div>
    
  );
}

export default ChatWindow;
