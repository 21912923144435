import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import s from '../styles/Edit__profile_photo.module.css';
import { AuthContext } from '../AuthContext';
import ImageUploader from './Foto'; // Импортируем компонент для загрузки и обрезки фото

function EditPhoto() {
  const [formData, setFormData] = useState({
    photos: Array(6).fill(null), // Массив для 6 фото
  });
  const [deletedPhotos, setDeletedPhotos] = useState([]); // Массив удалённых фото
  const [newPhotos, setNewPhotos] = useState([]); // Массив новых добавленных фото
  const [selectedPhotoIndex, setSelectedPhotoIndex] = useState(null); // Индекс текущего фото для обрезки
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { setCurrentUserProfile } = useContext(AuthContext);
  

  // Получаем профиль пользователя
  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await fetch('/api/profile', {
          method: 'GET',
          credentials: 'include',
        });
  
        if (response.ok) {
          const data = await response.json();
          setProfile(data);
  
          // Используем функциональное обновление для состояния formData
          setFormData((prevFormData) => ({
            ...prevFormData,
            photos: Array(6).fill(null).map((_, index) => data.photos[index] || null),
          }));
        } else {
          if (response.status === 404) {
            navigate('/CreateProfile');
          } else {
            setError('Error fetching profile');
          }
        }
      } catch (error) {
        setError('Error fetching profile');
        console.error('Error:', error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchProfile();
  }, [navigate]); // 'formData' больше не нужен в зависимостях
  
  

  // Обработка выбора фото для обрезки
  const handlePhotoSelect = (index) => {
    setSelectedPhotoIndex(index);
  };

  // Обработка загрузки нового фото
  const handlePhotoUpload = (blob) => {
    const updatedPhotos = [...formData.photos];
    updatedPhotos[selectedPhotoIndex] = {
      file: blob,
      oldFileName: profile.photos[selectedPhotoIndex], // Сохраняем старое имя файла
    };
    setFormData({ ...formData, photos: updatedPhotos });

    // Добавляем в список новых фото
    setNewPhotos((prev) => [...prev, blob]);

    setSelectedPhotoIndex(null);
  };

    // Отправка изменений на сервер
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const formDataObj = new FormData();
    const payload = {
      deletedPhotos: deletedPhotos.map((photo) => photo.oldFileName || photo),
      newPhotos: [],
    };

    // Добавляем новые фото в FormData
    newPhotos.forEach((photo, index) => {
      const fileName = `new_photo_${index + 1}.jpg`; // Генерируем имя для нового фото
      const file = new File([photo], fileName, { type: 'image/jpeg' });
      formDataObj.append('newPhotos', file);
      payload.newPhotos.push(fileName); // Добавляем имя файла в payload
    });

    // Добавляем payload в FormData
    formDataObj.append('payload', JSON.stringify(payload));

    fetch('/api/edit_profile', {
      method: 'POST',
      body: formDataObj,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);  // Выводим ответ в консоль для проверки
        if (data.message === 'Profile updated successfully') { 
          setSuccess(true);
          setCurrentUserProfile(true);
          navigate('/profile');
        }
      })
      .catch((error) => {
        setError('Error editing photo');
        console.error('Error editing photo:', error);
      });
  };


  // Удаление фото
  const handlePhotoDelete = (index) => {
    const updatedPhotos = [...formData.photos];
    const deletedPhoto = updatedPhotos[index]; // Получаем фото для удаления

    // Добавляем в список удаленных фото
    if (deletedPhoto && !deletedPhotos.includes(deletedPhoto)) {
      setDeletedPhotos((prev) => [...prev, deletedPhoto]);
    }

    updatedPhotos[index] = null;
    setFormData({ ...formData, photos: updatedPhotos });
  };



  return (
    <div>
      <h1 className="all_str_h1">Edit Photo</h1>

      {loading && <p className='loading'>Loading...</p>}
      {error && <p>{error}</p>}
      {success && <p>Photo edit successfully!</p>}

      {!loading && profile && (
        <div className={s.edit_container}>
            <div className={s.create_edit_form}>
          <form onSubmit={handleSubmit}>
            {/* Показываем карточки с фото */}
            {selectedPhotoIndex === null && (
            <div className={s.create_photo_grid}>
              {formData.photos.map((photo, index) => (
                <div key={index} className={s.photo_card}>
                  {photo ? (
                    <>
                      <img src={photo.file ? URL.createObjectURL(photo.file) : `/api/uploads/${photo}`} alt={` ${index + 1}`} />
                      <button className={s.create_batton_x} onClick={() => handlePhotoDelete(index)}></button>
                    </>
                  ) : (
                    <div className={s.create_placeholder} onClick={() => handlePhotoSelect(index)}></div>
                  )}
                </div>
                
              ))}
            </div>
            )}

            {/* Показываем компонент для обрезки, если выбрана карточка */}
            {selectedPhotoIndex !== null && (
              <ImageUploader setPhotoBlob={handlePhotoUpload} />
            )}
            {selectedPhotoIndex == null && (
            <div className={s.btn_edit_container}>
            <div className={s.btn_edit}>
              <button type="submit">Save Changes</button>
            </div>
            </div>
            )}
          </form>
          </div>
        </div>
      )}
      
    </div>
  );
}

export default EditPhoto;
