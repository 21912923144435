import React, { createContext, useState, useEffect } from 'react';

// Создаем контекст
export const AuthContext = createContext();

// Провайдер контекста
export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [currentUserProfile, setCurrentUserProfile] = useState(null);

  // Функция для получения текущего пользователя
  const fetchCurrentUser = async () => {
    try {
      const response = await fetch('/api/current_user', {
        credentials: 'include',  // Включаем куки для сессии
      });
  
      const data = await response.json();
      
      if (!data.authorized) {
        setCurrentUser(null);
      setCurrentUserProfile(null);  // Сбрасываем профиль
        // Пользователь не авторизован
        console.log('User is not authorized');
        
        // setCurrentUser(null);
      } else { if (!data.userprofile) {
        // Пользователь авторизован
        setCurrentUserProfile(null);
        setCurrentUser(data);
        console.log('User is not profile');
        
      } else {
        // Пользователь авторизован
        setCurrentUser(data);
        setCurrentUserProfile(data.userprofile);
        console.log(data.userprofile);
      } 
      }
      
    } catch (error) {
      console.error('Error fetching user:', error);
    }
  };
   

  // Выполняем получение текущего пользователя при монтировании компонента
  useEffect(() => {
    fetchCurrentUser();
  }, []);

  return (
    <AuthContext.Provider value={{ currentUser, fetchCurrentUser, setCurrentUser, currentUserProfile, setCurrentUserProfile }}>
      {children}
    </AuthContext.Provider>
  );
};
