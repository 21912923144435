
// import React, { useState, useEffect, useContext } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { AuthContext } from '../AuthContext'; // Подключаем AuthContext для автоматического входа
// import s from '../styles/Register.module.css';

// function Register() {
//   const [formData, setFormData] = useState({
//     username: '',
//     email: '',
//     password: '',
//     csrf_token: ''
//   });
//   const [error, setError] = useState('');
//   const [success, setSuccess] = useState(false);
//   const navigate = useNavigate();
//   const { fetchCurrentUser } = useContext(AuthContext); // Получаем функцию обновления пользователя

//   useEffect(() => {
//     // Получаем CSRF токен с сервера
//     fetch('/api/csrf_token')
//       .then((response) => response.json())
//       .then((data) => setFormData((prevFormData) => ({ ...prevFormData, csrf_token: data.csrf_token })));
//   }, []);

//   const handleChange = (e) => {
//     setFormData({ ...formData, [e.target.name]: e.target.value });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();  // Предотвращаем перезагрузку страницы

//     try {
//       // Отправляем запрос на регистрацию
//       const response = await fetch('/api/register', {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify(formData),
//       });
//       const result = await response.json();

//       if (response.ok) {
//         setSuccess(true);
//         // Если регистрация успешна, выполняем автоматический вход
//         const loginResponse = await fetch('/login', {
//           method: 'POST',
//           headers: {
//             'Content-Type': 'application/json',
//           },
//           body: JSON.stringify({ email: formData.email, password: formData.password }),
//         });

//         if (loginResponse.ok) {
//           // Если автологин успешен, обновляем текущего пользователя
//           await fetchCurrentUser();
//           // Перенаправляем пользователя в его профиль
//           navigate('/profile');
//         } else {
//           setError('Registration succeeded, but login failed. Please log in manually.');
//         }
//       } else {
//         setError(result.error);
//       }
//     } catch (err) {
//       setError('Something went wrong');
//     }
//   };

//   return (
//     <div>
//       <h1 className='all_str_h1'>Register</h1>
//       <div className={s.login_container}>
//     <div className={s.login_form}>
//       {error && <div className="error">{error}</div>}
//       {success ? (
//         <div>Registration successful!</div>
//       ) : (
//         <form onSubmit={handleSubmit}>
//           <div>
          
//             <input
//               type="text"
//               name="username"
//               value={formData.username}
//               onChange={handleChange}
//               required
//             />
//           </div>
//           <div>
            
//             <input
//               type="email"
//               name="email"
//               value={formData.email}
//               onChange={handleChange}
//               required
//             />
//           </div>
//           <div>
//             <input
//               type="password"
//               name="password"
//               value={formData.password}
//               onChange={handleChange}
//               required
//             />
//           </div>
//           <div className={s.btn_profile}> 
//              <button type="submit">Register</button>
//           </div>
         
//         </form>
//       )}
//     </div>
//     </div>
//     </div>
//   );
// }

// export default Register;
import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../AuthContext'; // Подключаем AuthContext для автоматического входа
import s from '../styles/Register.module.css';
import { useTranslation } from 'react-i18next';

function Register() {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    csrf_token: ''
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();
  const { fetchCurrentUser } = useContext(AuthContext); // Получаем функцию обновления пользователя
  const { t } = useTranslation();


  const handleGoogleLogin = useCallback(async (response) => {
    try {
      const { credential } = response;
      const googleResponse = await fetch('/api/googlelogin', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id_token: credential }), // Отправляем id_token от Google на сервер
      });

      if (!googleResponse.ok) {
        const data = await googleResponse.json();
        setError(data.error || 'Google login failed');
        return;
      }

      // Успешный вход через Google
      const data = await googleResponse.json();
      console.log('Google login successful:', data);

      // Обновляем данные текущего пользователя после входа через Google
      await fetchCurrentUser();

      // Перенаправляем пользователя
      navigate('/search');
    } catch (err) {
      setError('Error logging in with Google');
    }
  }, [fetchCurrentUser, navigate]);

  useEffect(() => {
    /* Загружаем Google Identity Services при монтировании компонента */
    window.google.accounts.id.initialize({
      client_id: '322100129717-tkkg9ak1aljhk4ji4jr310f27vgr8o4u.apps.googleusercontent.com', // Замените на ваш клиентский ID
      callback: handleGoogleLogin,
    });

    window.google.accounts.id.renderButton(
      document.getElementById('googleSignInDiv'),
      { theme: 'outline', size: 'large' }
    );
  }, [handleGoogleLogin]);

  useEffect(() => {
    // Получаем CSRF токен с сервера
    fetch('/api/csrf_token')
      .then((response) => response.json())
      .then((data) => setFormData((prevFormData) => ({ ...prevFormData, csrf_token: data.csrf_token })));
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();  // Предотвращаем перезагрузку страницы

    try {
      // Отправляем запрос на регистрацию
      const response = await fetch('/api/register', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });
      const result = await response.json();

      if (response.ok) {
        setSuccess(true);
        // Если регистрация успешна, выполняем автоматический вход
        const loginResponse = await fetch('/api/login', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email: formData.email, password: formData.password }),
        });

        if (loginResponse.ok) {
          // Если автологин успешен, обновляем текущего пользователя
          await fetchCurrentUser();
          // Перенаправляем пользователя в его профиль
          navigate('/profile');
        } else {
          setError('Registration succeeded, but login failed. Please log in manually.');
        }
      } else {
        setError(result.error);
      }
    } catch (err) {
      setError('Something went wrong');
    }
  };

  return (
    <div>
  <h1 className='all_str_h1'>{t("Register")}</h1>
  <div className={s.login_container}>
    <div className={s.login_form}>
      {error && <div className="error">{error}</div>}
      {success ? (
        <div>{t("Registration successful")}!</div>
      ) : (
        <form onSubmit={handleSubmit}>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              placeholder={t("Email")} // Добавлен placeholder
              autoComplete="email"
            />
            <input
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              required
              placeholder={t("Password")}  // Добавлен placeholder
              autoComplete="new-password"
            />
            <button type="submit">{t("Register_start")}</button>
        </form>
      )}
      {/* Кнопка входа через Google */}
      <div id="googleSignInDiv" className={s.google_sign_in}></div>
    </div>
  </div>
</div>

  );
}

export default Register;
