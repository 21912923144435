import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import s from '../styles/Search.module.css'; // Используем стили из Profile.css
import { useTranslation } from 'react-i18next';

function SearchProfiles() {
  const [profile, setProfile] = useState(null);  // Текущее состояние профиля
  const [nextProfile, setNextProfile] = useState(null);  // Следующий профиль (буферизация)
  const [loading, setLoading] = useState(true);  // Состояние загрузки
  const [loadingBufer, setLoadingBufer] = useState(false);
  const [error, setError] = useState(null);      // Ошибки
  const [noMoreProfiles, setNoMoreProfiles] = useState(false);  // Проверка на наличие профилей
  const [isProfileExpanded, setIsProfileExpanded] = useState(false); // Состояние для раскрытия профиля
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0); // Индекс текущего фото

  const isRequestInProgressRef = useRef(false);
  // const [isRequestInProgress, setIsRequestInProgress] = useState(false);  // Флаг для отслеживания выполнения запроса
  const navigate = useNavigate();
  const [isProfileChanging, setIsProfileChanging] = useState(false); 
  const { t } = useTranslation();


  const [matchInfo, setMatchInfo] = useState(null);  // Состояние для информации о совпадении (матче)



  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const [scale, setScale] = useState(0);
  const [direction, setDirection] = useState(null); // Направление движения
  // const [isTouching, setIsTouching] = useState(false); // Отслеживаем касание

 // Начальные значения по умолчанию, если нет данных в localStorage
 const [ageRange, setAgeRange] = useState([18, 35]);
 const [ageRangeLoaded, setAgeRangeLoaded] = useState(false); 

 const [gender, setGender] = useState("Any"); // начальное состояние выбора пола
 const [GenderLoaded, setGenderLoaded] = useState(false); 

 // Чтение диапазона из localStorage при загрузке компонента
 useEffect(() => {
   const savedRange = localStorage.getItem('ageRange');
   if (savedRange) {
     const parsedRange = JSON.parse(savedRange);
     setAgeRange(parsedRange); // Устанавливаем сохранённый диапазон
   } else {
     // Если в памяти ничего нет, оставляем начальное значение по умолчанию (18, 35)
     localStorage.setItem('ageRange', JSON.stringify([18, 35]));
   }
   setAgeRangeLoaded(true); // Флаг, что данные загружены
 }, []);

// Чтение диапазона из localStorage при загрузке компонента
useEffect(() => {
  const savedGender = localStorage.getItem('selectedGender');
  if (savedGender) {
    // Поскольку savedGender уже является строкой, нет необходимости использовать JSON.parse
    setGender(savedGender); // Устанавливаем сохранённое значение пола
  } else {
    // Если в памяти ничего нет, устанавливаем начальное значение по умолчанию "Any"
    localStorage.setItem('selectedGender', "Any");
    setGender("Any"); // Устанавливаем "Any" как значение пола по умолчанию
  }
  setGenderLoaded(true); // Флаг, что данные загружены
}, []);


  // Предварительная загрузка всех изображений
const preloadImages = (photos) => {
  if (photos && photos.length > 0) {
    photos.forEach((photo) => {
      const img = new Image();
      img.src = `/api/uploads/${photo}`;
      // Можно добавить обработчики onload и onerror для каждого изображения
      img.onload = () => {
        console.log(`Image ${photo} loaded`);
      };
      img.onerror = () => {
        console.error(`Error loading image ${photo}`);
      };
    });
  }
};




  // Функция для загрузки следующего профиля
  const fetchNextProfile = useCallback(async () => {
    try {
      setLoadingBufer(true)

      // Формируем URL с параметрами ageMin и ageMax
      const url = `/api/search?ageMin=${ageRange[0]}&ageMax=${ageRange[1]}&gender=${gender}`;

      const response = await fetch(url, {
        method: 'GET',
        credentials: 'include'
      });
      const data = await response.json();

      // Если профиль пользователя существует, продолжаем с проверкой на профили поиска
  if (response.status === 404 || response.status === 204) {
    setLoading(false);
    setNoMoreProfiles(true);  // Нет больше профилей для выдачи
    // setProfile(null);  // Очищаем текущий профиль
  } else {

      if (response.ok && data.profile) {
        setNextProfile({
          ...data.profile,
          photos: data.profile.photo ? data.profile.photo.split(',') : [],  // Массив фото
          
        }
      );
        // Пример использования функции для загрузки всех фото профиля
        if (data.profile.photo) {
          const photos = data.profile.photo.split(','); // Разделяем фото по запятой
          preloadImages(photos); // Предзагрузка всех изображений
        }
        
      } 
    }}
     catch (err) {
      console.error('Error fetching next profile');
    }
     finally {
      setLoadingBufer(false)
      // setLoading(false);  // Отключаем состояние загрузки
    }
  }, [ageRange, gender]);

  // Функция для получения профиля
  const fetchProfile = useCallback(async () => {
    try {
      setLoading(true);  // Включаем состояние загрузки при каждом запросе
      setProfile(null);  // Очищаем профиль перед новым запросом
      setIsProfileExpanded(false);  // Скрываем полные данные профиля при каждом запросе
      setScale(0);
      setDirection(null);

        const url = `/api/search?ageMin=${ageRange[0]}&ageMax=${ageRange[1]}&gender=${gender}`;
        const response = await fetch(url, {
        method: 'GET',
        credentials: 'include'  // Включаем куки для сессии
      });
      const data = await response.json();
      // Сначала проверяем наличие профиля авторизованного пользователя
if (data.userprofile) {
  // Если профиль пользователя не найден, перенаправляем на страницу создания профиля
  navigate('/CreateProfile');
} else {
  // Если профиль пользователя существует, продолжаем с проверкой на профили поиска
  if (response.status === 404 || response.status === 204) {
    setLoading(false);
    setNoMoreProfiles(true); 
    // setNoMoreProfiles(true);  // Нет больше профилей для выдачи
    
  } else {
    if (response.ok && data.profile) {
      setProfile({
        ...data.profile,
        photos: data.profile.photo ? data.profile.photo.split(',') : [],  // Массив фото
      });
      setNoMoreProfiles(false);  // Сбрасываем флаг, если профили есть
      // После загрузки текущего профиля, загружаем следующий
      fetchNextProfile();
    } else {
      setNoMoreProfiles(true);  // Если профилей больше нет
    }
  }
}

    } catch (err) {
      setError('Error fetching profile');
    } finally {
      setLoading(false);  // Отключаем состояние загрузки
    }
  }, [navigate, fetchNextProfile, ageRange, gender]);

// Переключение на следующий профиль
const handleNextProfile = useCallback(() => {
  if (nextProfile) {
    setProfile(nextProfile);  // Устанавливаем следующий профиль как текущий
    setNextProfile(null);  // Очищаем буфер
    setCurrentPhotoIndex(0);  // Сбрасываем индекс фото
    fetchNextProfile();  // Загружаем новый профиль в буфер
    setIsProfileExpanded(false);  // Скрываем полные данные профиля при каждом запросе
      setScale(0);
      setDirection(null);
      setLoadingBufer(false);
      console.log('if if');
      setTimeout(() => {
        isRequestInProgressRef.current = false;
      }, 500);
      
  }else if (noMoreProfiles) {
    // Если нет следующего профиля и профили закончились
    setProfile(null);  // Очищаем текущий профиль
    console.log('set profil null');
    setTimeout(() => {
      isRequestInProgressRef.current = false;
    }, 500);
  }else{
    console.log('else else');
    setIsProfileChanging(true);
    setLoading(true);
    setTimeout(() => {
      isRequestInProgressRef.current = false;
    }, 500);
  }
}, [nextProfile, fetchNextProfile, noMoreProfiles, setProfile]);





  // Используем useEffect для переключения на следующий профиль, но только если идет процесс смены профиля
  useEffect(() => {
    if (nextProfile && isProfileChanging && loading && !loadingBufer) {
      handleNextProfile();  // Переключаемся на следующий профиль
      setIsProfileChanging(false);  // Сбрасываем флаг после переключения
      setLoading(false)
      setTimeout(() => {
        isRequestInProgressRef.current = false;
      }, 500);  


    }
  }, [nextProfile, isProfileChanging, handleNextProfile,  loading, loadingBufer]);


  // Обработчик для обновления профиля после лайка/дизлайка
  const handleProfileChange = useCallback(() => {
    handleNextProfile();  // Загружаем следующий профиль после лайка/дизлайка
  }, [handleNextProfile]);

  const handSubscription = async() => {
    navigate('/Subscription');
  };

  // Функция для отправки лайка с проверкой состояния
  const handleLike = async () => {
    if (isRequestInProgressRef.current) 
      // {handleProfileChange();
      
      // return;} // Блокируем повторные запросы, если запрос уже выполняется
      return;

      isRequestInProgressRef.current = true; // Устанавливаем флаг выполнения запроса

        handleProfileChange();  // Обновляем профиль после лайка 
    

    try {

      const response = await fetch('/api/like', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({ profile_id: profile.user_id }),  // Отправляем ID профиля через тело запроса
      });

      if (response.ok) {
        const data = await response.json();
      if (data.match) {
        // Обновляем состояние, если произошел матч
        setMatchInfo({
          profileId: data.profile.id,
          name: data.profile.name,
          photo: data.profile.photo,  // Первое фото
        });
      }
        
      } else {
        const data = await response.json();
        setError(data.error || 'Failed to like the profile');
      }
    } catch (err) {
      setError('Error liking profile');
    } finally {

       
    }
  };

  // Функция для закрытия окна уведомления о матче
const handleCloseMatchNotification = () => {
  setMatchInfo(null);  // Сбрасываем информацию о матче, чтобы скрыть окно
};

  const handleI = async () => {
    setIsProfileExpanded(true);
  };

  const handleX = async () => {
    setIsProfileExpanded(false);
  };




  // Функция для отправки дизлайка с проверкой состояния
  const handleDislike = async () => {
    if (isRequestInProgressRef.current) {
      // handleProfileChange(); 
      return; }// Блокируем повторные запросы, если запрос уже выполняется

      isRequestInProgressRef.current = true; // Устанавливаем флаг выполнения запроса
    handleProfileChange(); 

    try {
      const response = await fetch('/api/dislike', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({ profile_id: profile.user_id }),  // Отправляем ID профиля
      });

      if (response.ok) {
        // handleProfileChange();  // Обновляем профиль после дизлайка
      } else {
        const data = await response.json();
        setError(data.error || 'Failed to dislike the profile');
      }
    } catch (err) {
      setError('Error disliking profile');
    } finally {
      // setTimeout(() => {
      //   isRequestInProgressRef.current = false;
      // }, 1000); 
      
    }
  };

  // Обработка начала касания
  const handleTouchStart = (e) => {
    setTouchStart(e.targetTouches[0].clientX);
    // setIsTouching(true); // Показываем картинку при касании
    setScale(0); // Сбрасываем масштаб до 0 при начале касания
  };

  const handleTouchMove = (e) => {
    const currentTouch = e.targetTouches[0].clientX;
    setTouchEnd(currentTouch);
    
  
    if (touchStart !== null) {
      const delta = currentTouch - touchStart;
  
      if (delta > 15&& !isRequestInProgressRef.current) {
        setDirection('right');
        setScale(0.4 + delta / 200); // Увеличиваем картинку динамически
      } else if (delta < -15&& !isRequestInProgressRef.current) {
        setDirection('left');
        setScale(0.4 + Math.abs(delta) / 200); // Увеличиваем картинку динамически
      }
      // Если пользователь вернулся близко к исходной точке касания
    else {
      setScale(0); // Сбрасываем картинку до исходного состояния
    }
    }
  };

  const handleTouchEnd = () => {
    if (touchStart && touchEnd) {
      const delta = touchEnd - touchStart;
  
      if (delta > 50 && !isRequestInProgressRef.current) {
        // Если движение вправо
        setDirection('right');

        setTimeout(() => {
          setScale((prevScale) => Math.min(prevScale + 0.2, 3)); // Увеличиваем картинку
        }, 10); // Задержка для применения transition
        
        
  
        setTimeout(() => {
          handleLike(); // Действие лайка
        //   setScale(1); // Сбрасываем масштаб
        }, 300); // Задержка для плавного отображения
      } else if (delta < -50&& !isRequestInProgressRef.current) {
        // Если движение влево
        setDirection('left');
        setTimeout(() => {
          setScale((prevScale) => Math.min(prevScale + 0.2, 3)); // Увеличиваем картинку
        }, 10); //
  
        setTimeout(() => {
          handleDislike(); // Действие дизлайка
        //   setScale(1); // Сбрасываем масштаб
          
        }, 300); // Задержка для плавного отображения
      }
      // Если свайп был незначительным
    else {
      setScale(0); // Сброс масштаба
    }
    }

    // Сбрасываем значения для следующего жеста
    setTouchStart(null);
    setTouchEnd(null);
    setScale(0);
    // setIsTouching(false); // Скрываем картинку после завершения касания
  };

    // Длительность анимации в миллисекундах
    const animationDuration = 500; // Увеличение до нужного размера за 0.5 сек

    // Обработчик для нажатия на кнопку лайка
  const handleLikeButton = () => {
    if (!isRequestInProgressRef.current) {
    setDirection('right2');
     // Добавляем небольшую задержку перед увеличением
     setTimeout(() => {
      setScale(1); // Увеличиваем масштаб
    }, 10); // Задержка для применения transition

    setTimeout(() => {
      handleLike(); // Действие лайка
      setScale(0); // Возвращаем масштаб к исходному
    }, animationDuration); // Задержка соответствует времени анимации
  }else{
    return;}
  };

  // Обработчик для нажатия на кнопку дизлайка
  const handleDislikeButton = () => {
    if (!isRequestInProgressRef.current) {
    setDirection('left2');
    setTimeout(() => {
      setScale(1); // Увеличиваем масштаб
    }, 10); // Задержка для применения transition

    setTimeout(() => {
      handleDislike(); // Действие дизлайка
      setScale(0); // Возвращаем масштаб к исходному
    }, animationDuration); // Задержка соответствует времени анимации
  }else{return;}};
  
  // Функции для смены фото
  const handleNextPhoto = () => {
    if (profile && profile.photos.length > 0) {
      setCurrentPhotoIndex((prevIndex) =>
        prevIndex === profile.photos.length - 1 ? 0 : prevIndex + 1
      );
    }
  };

  const handlePreviousPhoto = () => {
    if (profile && profile.photos.length > 0) {
      setCurrentPhotoIndex((prevIndex) =>
        prevIndex === 0 ? profile.photos.length - 1 : prevIndex - 1
      );
    }
  };

  // Обработчик свайпов
  const handlers = useSwipeable({
    onSwipedLeft: () => {
      if (!isProfileExpanded) {
        // handleDislike();  // Свайп влево - отправить дизлайк, если профиль не раскрыт
      } else {
        handleNextPhoto();  // Свайп влево переключает фото, если профиль раскрыт
      }
    },
    onSwipedRight: () => {
      if (!isProfileExpanded) {
        // handleLike();  // Свайп вправо - отправить лайк, если профиль не раскрыт
      } else {
        handlePreviousPhoto();  // Свайп вправо переключает фото, если профиль раскрыт
      }
    },
    onSwipedUp: () => {
      if (!isProfileExpanded) {
        setIsProfileExpanded(true);  // Свайп вверх - раскрыть профиль только если он еще не раскрыт
      }
    },  // Свайп вверх - раскрыть профиль
    onSwipedDown: () => setIsProfileExpanded(false),  // Свайп вниз - вернуть состояние для лайков/дизлайков
    preventScrollOnSwipe: false,// !isProfileExpanded, 
    delta: 20  // Минимальное расстояние для распознавания свайпа
  });

  // Вызываем fetchProfile только после того, как ageRange загружен
  useEffect(() => {
    if (ageRangeLoaded&& GenderLoaded) {
      fetchProfile();
    }
  }, [ageRangeLoaded, GenderLoaded, fetchProfile]);
  
  if (loading ) {
    return <div className='loading'>{t("Loading")}...</div>;
  }

  if (noMoreProfiles && !profile&& !matchInfo) {
    return <div className='loading'>{t("No more profiles")}.</div>  // Сообщение, если профили закончились
  }

  if (noMoreProfiles && !profile&& matchInfo) {
    return (
    <div>
    <div className='loading'>{t("No more profiles")}.</div> 
    <div className={s.match_notification}>
    <p>{t("It's a match with")} {matchInfo.name}!</p>
    <img src={`/api/uploads/${matchInfo.photo}`} alt="Match Profile" />
    <button onClick={handleCloseMatchNotification}>{t("Close")}</button>
  </div>
  </div>
    )
  }


  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div>
    {/* <h1 className='all_str_h1'>{t("Search Profile")}</h1> */}
    {/* Индикаторы фото отображаются только в режиме просмотра профиля */}
    <div>
    {/* Окно уведомления о совпадении */}
    {matchInfo && (
      <div className={s.match_notification}>
        <p>It's a match with {matchInfo.name}!</p>
        <img src={`/api/uploads/${matchInfo.photo}`} alt="Match Profile" />
        <button onClick={handleCloseMatchNotification}>{t("Close")}</button>
      </div>
    )}

    {/* Остальной JSX компонента */}
    <div {...handlers} className={s.profile_container}>
      {/* ваш остальной код */}
    </div>
  </div>
    <div {...handlers} className={s.profile_container}>
      {profile ? (
        <div  className={s.profile}>
          {!isProfileExpanded && (
             <>
            {!isProfileExpanded && profile.photos.length > 1 && (
                        <div className={s.indicators}>
                          {profile.photos.map((photo, index) => (
                            <span
                              key={index}
                              className={`dot ${index === currentPhotoIndex ? 'active_dot' : ''}`}
                            ></span>
                          ))}
                        </div>
                      )}
          
            

          <div className={s.img}>
          
           
            <img src={`/api/uploads/${profile.photos[currentPhotoIndex]}`} alt="Profile" className={s.styled_image} />
            
            {!isProfileExpanded &&  profile.photos.length > 1 && (
              <>
                <button className={s.prev} onClick={handlePreviousPhoto}>{'<'}</button>
                <button className={s.next} onClick={handleNextPhoto}>{'>'}</button>
              </>
            )}
            <div className={s.profile_bio_div2}>
          <div className={s.profile_bio2}>
            <div className={s.name_age}>
              <p>{profile.name}</p>
              <p>{profile.age}</p>
              {/* <button className={s.VIP_bar} onClick={handleLike}>
                <img className={s.VIP_img} src={`${process.env.PUBLIC_URL}/VIP.svg`} alt="VIP" />
              </button> */}
              
            </div>
            {/* <p>{t('gender.' + profile.gender)}</p> */}
            {/* <p>{t("City")}: {profile.city}</p> */}
            <p>{t("Distance")}: {profile.distance} km</p>
          </div>
          </div>
            {!isProfileExpanded && (
              <>
                {/* <button className="dislike" onClick={handleDislike}>
                <img src={`${process.env.PUBLIC_URL}/like.svg`} alt="Dislike" />
              </button>
                <button className="like" onClick={handleLike}>
                <img src={`${process.env.PUBLIC_URL}/2like.svg`} alt="Like" />
              </button> */}
                <div 
                  className={s.swipe_carding}
                  onTouchStart={handleTouchStart}
                  onTouchMove={handleTouchMove}
                  onTouchEnd={handleTouchEnd}
                  
                >
                  {direction === 'left' && (
                    <img
                      src={`${process.env.PUBLIC_URL}/dislike.svg`}
                      alt="Left swipe"
                      className={s.swipe_image}
                      style={{ transform: `scale(${scale})` }}
                    />
                  )}
                  {direction === 'right' && (
                    <img
                      src={`${process.env.PUBLIC_URL}/like3.svg`}
                      alt="Right swipe"
                      className={s.swipe_image}
                      style={{ transform: `scale(${scale})` }}
                    />
                  )}
                </div>
                


                <div className={s.swipe_carding2}>
        {direction === 'left2' && (
          <img
            src={`${process.env.PUBLIC_URL}/dislike.svg`}
            alt="Left swipe"
            className={s.swipe_image2}
            style={{
              transform: `scale(${scale})`,
              transition: `transform ${animationDuration}ms ease-in-out`, // Плавное изменение масштаба
            }}
          />
        )}
        {direction === 'right2' && (
          <img
            src={`${process.env.PUBLIC_URL}/like3.svg`}
            alt="Right swipe"
            className={s.swipe_image2}
            style={{
              transform: `scale(${scale})`,
              transition: `transform ${animationDuration}ms ease-in-out`, // Плавное изменение масштаба
            }}
          />
        )}
      </div>
                
                            </>
            )}
          </div>
          </>
            )}
            {!isProfileExpanded && (
              <>
            <div className={s.likes_bar}>
              <button className={s.dislike_bar} onClick={handleDislikeButton}>
                <img className={s.bar_img} src={`${process.env.PUBLIC_URL}/search_dislike.svg`} alt="handleDislike" />
              </button>
              <button className={s.i_bar} onClick={handleI}>
                <img className={s.bar_i_img} src={`${process.env.PUBLIC_URL}/search_i.svg`} alt="Like" />
              </button>
                <button className={s.like_bar} onClick={handleLikeButton}>
                <img className={s.bar_img} src={`${process.env.PUBLIC_URL}/search_like.svg`} alt="Like" />
              </button>
            </div>
            </>
            )}

          

          {/* Основная информация о профиле */}
          {!isProfileExpanded && (
          <div className={s.profile_bio_div}>
          <div className={s.profile_bio}>
            <div className={s.name_age}>
              {/* <p>{profile.name}</p>
              <p>{profile.age}</p> */}
              <button className={s.VIP_bar2} onClick={handSubscription}>
                <img className={s.VIP_img2} src={`${process.env.PUBLIC_URL}/VIP.svg`} alt="VIP" />
              </button>
              
            </div>
            <p>{t('gender.' + profile.gender)}</p>
            <p>{t("City")}: {profile.city}</p>
            {/* <p>{t("Distance")}: {profile.distance} km</p> */}
          </div>
          </div>
          )}

          {/* Основная информация о профиле */}
          {isProfileExpanded && (
          <div className={s.profile_bio_div}>
          <div className={s.profile_bio}>
            <div className={s.name_age}>
              <p>{profile.name}</p>
              <p>{profile.age}</p>
              <button className={s.x_bar} onClick={handleX}>
                <img className={s.bar_x_img} src={`${process.env.PUBLIC_URL}/search_x.svg`} alt="Like" />
              </button>
              <button className={s.VIP_bar} onClick={handSubscription}>
                <img className={s.VIP_img} src={`${process.env.PUBLIC_URL}/VIP.svg`} alt="VIP" />
              </button>
              
            </div>
            <p>{t('gender.' + profile.gender)}</p>
            <p>{t("City")}: {profile.city}</p>
            <p>{t("Distance")}: {profile.distance} km</p>
          </div>
          </div>
            )}
          
            <div className={s.full_bio}>
                    {/* Отображаем полные данные профиля при свайпе вверх */}
                      {isProfileExpanded && (
                        <div className={s.profile_bio_div}>
                        <div className={s.bio_card}>
                          <p>{profile.bio}</p>
                        </div>
                        </div>
                      )}
                </div>
                </div>
        
      ) : (
        noMoreProfiles  ? (
          <div>No more profiles available.</div>  // Сообщение, если профили закончились
        ) : (
          <div className='loading'>No profiles found</div>  // Если профилей нет
        )
      )}
    </div>
    
   
    </div>
  );
}

export default SearchProfiles;

