
import React, { useState, useEffect, useCallback } from 'react';
import s from '../styles/WhoLike.module.css';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

function WhoLike() {
  const [likers, setLikers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { t } = useTranslation();

  // Мемоизируем функцию fetchLikers с помощью useCallback
  const fetchLikers = useCallback(async () => {
    try {
      const response = await fetch('/api/likes', {
        method: 'GET',
        credentials: 'include',  // Включаем куки для сессии
      });

      // Проверка на статус 401 (неавторизован)
      if (response.status === 401) {
        setError('Unauthorized. Please log in.');
        navigate('/login');  // Перенаправляем на страницу логина
        return;
      }

      if (!response.ok) {
        const data = await response.json();
        setError(data.error || 'Failed to load likers');
        return;
      }

      const data = await response.json();
      setLikers(data.likers);
    } catch (error) {
      setError('Error loading likers');
    } finally {
      setLoading(false);
    }
  }, [navigate]);  // Добавляем navigate в зависимости

  // Загружаем совпадения при монтировании компонента
  useEffect(() => {
    fetchLikers();
  }, [fetchLikers]);  // Теперь fetchLikers в зависимостях

  // Функция обработки клика на карточку профиля
  const handleProfileClick = (profileId) => {
    navigate(`/profile/${profileId}`);
  };

  if (loading) {
    return <div>{t("Loading")}...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div>
      <h1 className="all_str_h1">{t("Likers")}</h1>
      <div className={s.back}>
          <Link to="/search" className={s.btn_prev_2}>
            X
          </Link>                 
      </div>
      {likers.length > 0 ? (
        <div className={s.likers_container}>
          {likers.map((profile) => (
            <div
              key={profile.id}
              className={s.likers}
              onClick={() => handleProfileClick(profile.id)} /* Обработчик клика */
            >
              <img src={`/api/uploads/${profile.latest_photo}`} alt="Profile" />
              <p>{profile.name} {profile.age}</p>
            </div>
          ))}
        </div>
      ) : (
        <div className='loading'>
          {t("no_likes")}</div>
      )}
    </div>
  );
}

export default WhoLike;

