import React, { useState, useEffect } from 'react';
import s from '../styles/Matches.module.css';
import { useNavigate } from 'react-router-dom';
// import Unmatch from './Unmatch';  // Импортируем компонент Unmatch
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

function Matches() {
  const [matches, setMatches] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { t } = useTranslation();

  // Функция для получения списка совпадений
  const fetchMatches = async () => {
    try {
      const response = await fetch('/api/matches', {
        method: 'GET',
        credentials: 'include',  // Включаем куки для сессии
      });

      if (!response.ok) {
        const data = await response.json();
        setError(data.error || 'Failed to load matches');
        return;
      }

      const data = await response.json();
      setMatches(data.matches);
    } catch (error) {
      setError('Error loading matches');
    } finally {
      setLoading(false);
    }
  };

  // Загружаем совпадения при монтировании компонента
  useEffect(() => {
    fetchMatches();
  }, []);

  // Функция обработки клика на карточку профиля
  const handleProfileClick = (profileId) => {
    if (profileId) {
      navigate(`/UsersProfile/${profileId}`);  // Передаем правильный ID профиля
    } else {
      console.error('Profile ID is undefined');
    }
  };

  if (loading) {
    return <div className='loading'>{t("Loading")}...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div>
    <h1 className='all_str_h1'>{t("Matches")}</h1>
    <div className={s.back}>
          <Link to="/search" className={s.btn_prev_2}>
            X
          </Link>                 
      </div>
    {matches.length > 0 ? (
      <div className={s.match_container}>
        {matches.map((profile) => (
          <div key={profile.id}
           className={s.match}
           onClick={() => handleProfileClick(profile.id)} /* Обработчик клика */ 
           >
            <img src={`/api/uploads/${profile.latest_photo}`} alt="Profile"  />
            <p>{profile.name}  {profile.age}</p>
            
            {/* Добавляем кнопку Unmatch */}
            {/* <Unmatch profileId={profile.id} onUnmatchSuccess={fetchMatches} /> */}
          </div>
        ))}
      </div>
    ) : (
      <div className='loading'>
        {t("don't have any matches")}</div>
    )}
  </div>
    
  );
}

export default Matches;
