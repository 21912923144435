

import React, { useState, useEffect } from 'react';
import s from '../styles/Subscription.module.css';
import { useTranslation } from 'react-i18next';

const Subscription = () => {
  const [hasSubscription, setHasSubscription] = useState(null); // Состояние для хранения информации о подписке
  const [loading, setLoading] = useState(true); // Состояние для отображения загрузки
  const [error, setError] = useState(null); // Состояние для ошибок
  const { t } = useTranslation(); 
  const [selectedSubscription, setSelectedSubscription] = useState(null);


  // Данные по вариантам подписки
  const subscriptions = [
    { id: 1, title: '1 Week', price: '$5', description: 'Enjoy a one-week premium subscription with all features.' },
    { id: 2, title: '1 Month', price: '$15', description: 'Unlock all features for a full month.' },
    { id: 3, title: '6 Months', price: '$70', description: 'Half-year of premium access at a discounted rate.' },
    { id: 4, title: '1 Year', price: '$120', description: 'Best value: get premium access for a whole year.' }
  ];


  // Имитация проверки подписки (можно заменить на реальный API-запрос)
  const checkSubscription = async () => {
    try {
      setLoading(true); // Показываем индикатор загрузки
      // Здесь можно сделать API-запрос для проверки подписки пользователя
      const response = await fetch('/api/subscription', { credentials: 'include' });
      
      const data = await response.json();
      setHasSubscription(data.isActive); // Устанавливаем состояние подписки
    } catch (err) {
      setError('Error checking subscription');
    } finally {
      setLoading(false); // Отключаем индикатор загрузки
    }
  };

  // Выполняем проверку подписки при загрузке компонента
  useEffect(() => {
    checkSubscription();
  }, []);

  const handleCardClick = (subscription) => {
    setSelectedSubscription(subscription);
  };

  // Сбрасываем выбранную подписку (возврат к списку)
  const handleBack = () => {
    setSelectedSubscription(null);
  };

  // Обработка загрузки и ошибок
  if (loading) {
    return <div>{t("Loading")}...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  // Если подписка активна, показываем основной контент
  if (hasSubscription) {
    return (
      <div>
        <h1>Welcome to the premium content!</h1>
        <p>Enjoy all the features of your subscription.</p>
      </div>
    );
  }

  // Если подписки нет, показываем предложение приобрести её
  return (
    <div>
    {/* <div>
      <h1 className={s.h1}>No Subscription</h1>
      <p>You don't have an active subscription. Please consider subscribing to access premium content.</p>
      <button onClick={() => alert('Redirecting to purchase page...')}>
        Buy Subscription
      </button>
    </div> */}
  
  <div className={s.subscription_container}>
      <h1>Choose Your Subscription Plan</h1>
      
      {/* Если выбрана конкретная подписка, показываем её подробности */}
      {selectedSubscription ? (
        <div className={s.subscription_details}>
          <h2>{selectedSubscription.title}</h2>
          <p>{selectedSubscription.description}</p>
          <p>Price: {selectedSubscription.price}</p>
          <button onClick={handleBack}>Go Back</button>
        </div>
      ) : (
        <div className={s.subscription_grid}>
          {subscriptions.map((subscription) => (
            <div
              key={subscription.id}
              className={s.subscription_card}
              onClick={() => handleCardClick(subscription)}
            >
              <h3>{subscription.title}</h3>
              <p>{subscription.price}</p>
            </div>
          ))}
        </div>
      )}

      {/* Описание преимуществ подписки */}
      <div className={s.subscription_benefits}>
        <h2>Why Upgrade to Premium?</h2>
        <ul>
          <li>Access to unlimited matches</li>
          <li>Send unlimited messages</li>
          <li>See who liked your profile</li>
          <li>Get priority support</li>
          <li>Boost your profile visibility</li>
        </ul>
      </div>
    </div>
    </div>
  );
};

export default Subscription;
