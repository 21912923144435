import React from 'react';
import { useNavigate } from 'react-router-dom';  // Импортируем хук useNavigate
import s from '../styles/Home.module.css';
import { AuthContext } from '../AuthContext';
import { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const Home = () => {
  const navigate = useNavigate();  // Используем хук для программной навигации
  const { currentUser } = useContext(AuthContext); // Получаем текущее состояние пользователя
  const { t, i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false); 
  const languages = [
    { code: 'en', label: 'English' },
    { code: 'ru', label: 'Русский' },
  ];

  // Функция для смены языка
  const changeLanguage = (code) => {
    i18n.changeLanguage(code); // Меняем язык
    localStorage.setItem('language', code); // Сохраняем выбор пользователя
    setIsOpen(false); // Закрываем меню после выбора
  };

  // Восстанавливаем язык при загрузке приложения
useEffect(() => {
  const savedLanguage = localStorage.getItem('language') || 'ru'; // По умолчанию английский
  i18n.changeLanguage(savedLanguage);
}, [i18n]);
 


  const handleGetStarted = () => {
    navigate('/register');  // Перенаправляем пользователя на страницу регистрации
  };
  const handleProfile = () => {
    navigate('/search');  // Перенаправляем пользователя на страницу регистрации
  };
  const handleGetLogin = () => {
    navigate('/login');  // Перенаправляем пользователя на страницу регистрации
  };
  return (
    <div>
      <div style={{ position: 'absolute', display: 'inline-block',marginTop: '70px', right: '30px'  }}>
      {/* Кнопка для открытия меню */}
      <button onClick={() => setIsOpen(!isOpen)}>
      Language
      </button>

      {/* Выпадающее меню */}
      {isOpen && (
        <ul style={{
          position: 'absolute',
          top: '100%',
          left: 0,
          backgroundColor: '#fff',
          border: '1px solid #ccc',
          listStyle: 'none',
          padding: 0,
          margin: 0,
          width: '100px',
        }}>
          {languages.map((language) => (
            <li key={language.code}>
              <button
                style={{
                  width: '100%',
                  padding: '10px',
                  textAlign: 'left',
                  background: 'none',
                  border: 'none',
                  cursor: 'pointer',
                }}
                onClick={() => changeLanguage(language.code)}
              >
                {language.label}
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
      <div className={s.home_container}>
        {/* Flexbox контейнер для центровки */}
        <div className={`${s.row} ${s.text_center}`}>
          <div className={s.home_1}>
            <h1 className={s.home_title}>{t('welcome')}</h1>
            <p className={s.home_description}>
              {t('Find your perfect match')}
            </p>
            <ul>
              {currentUser ? (
                <div className={s.get_started}>
                  <button className={s.get_started_btn} onClick={handleProfile}>
                     {t('Search')}
                  </button>
                  {/* <div>
                  <button onClick={() => changeLanguage('en')}>English</button>
                  <button onClick={() => changeLanguage('ru')}>Русский</button>
      
                  </div> */}
                </div>
              ) : (
                <div className={s.get_started}>
                  <button className={s.get_started_btn} onClick={handleGetLogin}>
                    {t('Sign-in')}
                  </button>
                  <button className={s.get_started_btn} onClick={handleGetStarted}>
                    {t('Try Now')}
                  </button>
                </div>
                
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
